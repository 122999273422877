@tailwind base;
@tailwind components;
@tailwind utilities;

/*styles.css*/
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(rgb(17, 24, 39), rgb(243, 244, 246), rgb(17, 24, 39));
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  margin-left: 14px;
}

a,
a:visited {
  color: black;
}

.box {
  background: peru;
  padding: 16px 24px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.01);

  &::after {
    position: absolute;
    content: "";
    top: 15px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(
      to left,
      #cd853f,
      #ffffff
    );
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

